<template>
  <div class="st-item card" :class="bgIconClass">
    <div class="header st-border-bottom">
      <h3 :class="iconClass">{{title}}</h3>
      <p>{{rightText}}</p>
    </div>
    <div class="container tracing-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String
    },
    rightText: {
      type: String
    },
    icon: {
      type: String,
      validator (value) {
        return ['report', ''].indexOf(value) !== -1
      }
    },
    bgIcon: {
      type: String,
      validator (value) {
        return ['pass', ''].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    iconClass () {
      return this.icon ? 'title-icon__' + this.icon : ''
    },
    bgIconClass () {
      return this.bgIcon ? 'card-icon__' + this.bgIcon : ''
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  &.card-icon__pass {
    position: relative;
    &::after {
      content: '';
      width: 1.36rem;
      height: 1.36rem;
      background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/pass-big.png") center center no-repeat;
      background-size: auto 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .27rem .4rem;
    border-bottom: 1px solid #eee;
    h3 {
      font-size: .28rem;
      color: #28BE9C;
      line-height: .54rem;
      padding-left: .28rem;
      position: relative;
      &::after {
        content: '';
        width: .08rem;
        height: .36rem;
        background: #28BE9C;
        border-radius: .04rem;
        margin-top: -.18rem;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
    .title-icon__report {
      line-height: .64rem;
      padding-left: .8rem;
      &::after {
        width: .64rem;
        height: .64rem;
        background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/certificate-icon.png") center center no-repeat;
        background-size: auto 100%;
        border-radius: 0;
        margin-top: -.32rem;
      }
    }
    p {
      font-size: .3467rem;;
    }
  }
  .container {
    font-size: .28rem;
    line-height: .5rem;
    color: #777;
    padding: .14rem .4rem;
    li {
      padding: .16rem 0;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      h3 {
        max-width: 40%;
      }
      p {
        max-width: 60%;
      }
    }
    .time-line {
      padding-left: .4rem;
      position: relative;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: .32rem;
      }
      &::before {
        width: .028rem;
        height: 100%;
        background: #ECECEC;
        left: .126rem;
      }
      &::after {
        width: .28rem;
        height: .28rem;
        background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/time-mini.png") center center no-repeat;
        background-size: 100% auto;
        left: 0;
      }
      &:nth-last-of-type(1) {
        &::before {
          height: calc(100% - 0.32rem);
        }
      }
      h4 {
        font-size: .3467rem;
        color: #000;
        line-height: .4rem;
        padding: .26rem 0;
      }
      ul {
        padding: .08rem 0;
      }
    }
  }
  .tracking-item {
    position: relative;
    &::after {
      content: '';
      width: 2.62rem;
      height: 2.62rem;
      background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/tracing-big.png") center center no-repeat;
      background-size: auto 100%;
      position: absolute;
      right: .3rem;
      top: 0;
    }
  }
}
</style>
