<template>
  <van-list
    v-model="loading.load"
    :finished="loading.finished"
    :finished-text="finishedText"
    :error.sync="loading.error"
    :error-text="errorText"
    @load="onLoad">
    <slot></slot>
  </van-list>
</template>

<script>
import { List  } from 'vant'
export default {
  name: 'Lodaing',
  components: {
    [List.name]:List
  },
  props: {
    loading: {
      type: Object
    }
  },
  data () {
    return {
      finishedText: '',
      errorText: '请求失败，点击重新加载'
    }
  },
  methods: {
    onLoad () {
      this.$emit('on-load')
    }
  }
}
</script>
