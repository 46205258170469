<template>
  <div class="tracking-warpper">
    <div class="banner">
      <img 
        :src="seller.food_big_type == 101 ?
          'https://static.smartracing.cn/images/yz-payment-code/banner-yz-pf-meat.png' :
          'https://static.smartracing.cn/images/yz-payment-code/banner-yz-pf.png'">
    </div>

    <div class="st-spacing-main">
      <card title="主体信息" v-if="seller.name || seller.market_name">
        <ul class="st-list-body">
          <li v-if="seller.name"> 主体名称： {{ seller.name }}</li>
          <li v-if="seller.market_name"> 主体地址： {{ seller.market_name }}{{ seller.customCode }}摊位</li>
          <li v-if="seller.name"> 店铺名称： {{ seller.name }}</li>
          <li v-if="seller.desc"> 经营范围： {{ seller.desc }}</li>
        </ul>
      </card>
      <card title="产品信息">
        <div class="product-main">
          <lodaing :loading="loading" @on-load="onLoad">
            <div class="product-group" v-if='products.length'>
              <div
                v-for="(item, index) in products"
                :key="index"
                class="product-list">
                <div class="image" @click="productTap(item)">
                  <img :src="item.imgUrl  + '?x-oss-process=style/250_auto'" alt="">
                </div>
              </div>
            </div>
            <div class="product-group" v-if='orderTraceProducts.length'>
              <div
                v-for="(item, index) in orderTraceProducts"
                :key="index"
                class="product-list">
                <div class="image" @click="productTap(item, true)">
                  <img :src="item.img" alt="">
                </div>
              </div>
            </div>
          </lodaing>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Card from './components/Card.vue'
import Lodaing from './components/Lodaing'
export default {
  name: 'Tracking',
  components: {
    [Toast.name]:Toast,
    Card,
    Lodaing
  },
  data () {
    return {
      products: [], // 手工溯源
      orderTraceProducts: [], // 订单溯源
      seller: {},
      loading: {
        load: false,
        finished: false,
        error: false
      },
      pagination: {
        currentPage: 1
      },
      profileId: ''
    }
  },
  mounted () {
    this.profileId = this.$route.query.id
    this.getSeller()
  },
  filters: {
    itemFil (item, shop) {
      item.seller = shop
      return item
    }
  },
  methods: {
    getSeller () {
      this.$api.orderTracking.findOne({
        shopId: this.profileId
      }).then(res => {
        if (res.data.success) {
          this.seller = res.data.result
        } else {
          Toast('获取商户信息失败，请重新刷新')
        }
      })
    },
    onLoad () {
      this.getProducts(this.pagination.currentPage)
    },
    getProducts (page) {
      this.$api.orderTracking.handmadeTrace({
        type: 2,
        b_profileid: this.profileId,
        limit: 12,
        page: page
      }).then(res => {
        if (res.data.success) {
          this.loading.load = false
          let lists = res.data.result.items
          if (lists.length > 0) {
            this.getImages(lists)
            this.pagination.currentPage += 1
          } else {
            if (page === 1) {
              this.getOrderTrace(this.profileId)
            }
            this.loading.finished = true
          }
        } 
        // else {
        //   Toast('获取店铺商品失败，请重新刷新')
        //   this.loading.load = false
        //   this.loading.error = true
        // }
      })
      // .catch(() => {
      //   Toast('获取店铺商品失败，请重新刷新')
      //   this.loading.load = false
      //   this.loading.error = true
      // })
    },
    getImages (lists) {
      let idArray = []
      lists.forEach(item => idArray.push(item.product.id))
      let idString = idArray.join(',')
      this.$api.orderTracking.images({ids: idString}).then(res => {
        if (res.data.success) {
          let imageArray = res.data.result
          lists.forEach(list => {
            let n = imageArray.findIndex(item => item.id === list.product.id )
            list.imgUrl = imageArray[n].imgUrl
            list.sku = { name: list.product.name }
          })
          this.products = [...this.products, ...lists]
        }
      })
      
    },
    productTap (item, isOrderTrace) {
      if (isOrderTrace) {
        this.$router.push({
          name: 'Product',
          query: {
            productId: item.product_id,
            profileId: this.profileId,
            isOrderTrace: true
          }
        })
      } else {
        this.$router.push({
          name: 'Product',
          query: {
            productId: item.product.id,
            profileId: this.profileId
          }
        })
      }
      
    },
    getOrderTrace (profile_id) {
      this.$api.orderTracking.orderTrace({profile_id}).then(res => {
        this.orderTraceProducts= res.data.result
      })
    }
  }
}
</script>

<style lang="less" scoped>

.st-spacing-main{
  margin: .4rem 0;
}

.card {
  width: 6.9rem;
  text-align: center;
  margin: .2rem auto 0;
  padding: 0;
}
li{
  justify-content: flex-start !important;
  text-align: left;
  span{
    &:nth-child(1){
      width: 1.4rem;
    }
    &:nth-child(2){
      flex: 1;
    }
  }
}

.product-main {
  padding: .32rem 0 0;
  .van-checkbox-group,
  .product-group {
    display: flex;
    flex-wrap: wrap;
  }
  .product-list {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 .33rem .33rem 0;
    border-radius: .14rem;
    overflow: hidden;
    position: relative;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    .image {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: .14rem;
      overflow: hidden;
    }
  }
}
</style>
